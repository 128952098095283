ion-button {
  height: 3rem;
  min-width: 11rem;
  text-transform: none;
  --border-radius: 0;
  --box-shadow: none;

  &.secondary {
    color: var(--bbva-medium-blue);
  }

  &:hover {
    color: var(--ion-color-secondary-contrast);
    --background-hover: var(--bbva-core-blue-light);
  }
}

.page {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;

  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --padding-start: 3.19rem;
    --padding-end: 3.19rem;
  }
}

.g-divider {
  height: 0.0625rem;
  width: 100%;
  background-color: var(--bbva-500);
  opacity: 0.1;
  margin: 0 1rem 0 3rem;
}

.g-toast {
  color: var(--white);
  --background: var(--bbva-dark-red);
  --button-color: var(--white);

  font-size: 1.2rem;
  font-weight: $medium;
}

.g-spinner {
  width: 100%;
  margin: 1rem;
}
