// Custom variables

$ion-grid-breakpoints: (
  xs: 320px,
  sm: 640px,
  md: 800px,
  lg: 1024px,
  xl: 1280px,
);

// sizes
$very-small: 0.5rem;
$small: 1rem;
$small-medium: 1.5rem;
$medium-size: 2rem; 
$medium-long: 2.5rem;
$long: 3rem;
$long-xl: 3.5rem;
$xl: 4rem;

// font-weigh
$book: 300;
$medium: 500;
$medium-semi-bold: 550;
$semi-bold: 600;
$bold: 700;

