.c-number-input {
  --background: var(--bbva-100);
  --color: var(--bbva-500);
  --min-height: 4.5rem;
  --border-width: 0;
  --inner-border-width: 0;
  --full-highlight-height: 0;
  max-width: 4.5rem;
  font-size: 2rem;

  ion-input {
    --color: var(--bbva-medium-blue);
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &--error {
    --background: var(--bbva-lighter-coral);
    --color: var(--bbva-medium-blue);
  }
}
