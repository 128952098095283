// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: var(--bbva-dark-aqua);
  --ion-color-primary-lighter: var(--bbva-aqua);
  --ion-color-primary-rgb: 2, 132, 132;
  //--ion-color-primary-contrast: #ffffff;
  //--ion-color-primary-contrast-rgb: 255, 255, 255;
  //--ion-color-primary-shade: #3171e0;
  //--ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: var(--bbva-dark-core-blue);
  --ion-color-secondary-lighter: var(--bbva-core-blue);
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: var(--grey-800);
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-colo-medium-gray: var(--bbva-300);
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-gray: var(--bbva-100);
  --ion-color-light-blue: var(--bbva-lighter-blue);
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /*font family*/
  --ion-font-family: 'BentonSansBBVA';

  /** bbva colors **/
  --bbva-medium-blue: #1973b8;
  --bbva-lighter-blue: #237aba;
  --bbva-white-core-blue: #1464a5;
  --bbva-core-blue: #004481;
  --bbva-core-blue-light: #1464a5;
  --bbva-dark-core-blue: #043263;

  --bbva-dark-aqua: #028484;
  --bbva-aqua: #2dcccd;

  --bbva-100: #f4f4f4;
  --bbva-150: #f9f9f9;
  --bbva-170: #dddfe2;
  --bbva-200: #e9e9e9;
  --bbva-300: #d3d3d3;
  --bbva-400: #d8d8d8;
  --bbva-450: #aeaeae; // It's not an official BBVA styleguide color
  --bbva-500: #666666;
  --bbva-550: #4a4a4a;
  --bbva-600: #121212;

  --bbva-black-200: var(--bbva-200);
  --bbva-black-300: var(--bbva-300);
  --bbva-black-400: #bdbdbd;
  --bbva-black-600: #888888;
  --bbva-red: #da3851;
  --bbva-dark-red: #b92a45;
  --bbva-med-red: #c0475e;
  --bbva-light-red: #f4c3ca;
  --white: #ffffff;

  --bbva-lighter-coral: #faecec;

  --bbva-red: #da3851;
  --bbva-yellow: #f8cd51;
  --bbva-green: #48ae64;
  --bbva-info-disclaimer: #e9f6fd;
  --bbva-purple: #433dc5;

  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}
