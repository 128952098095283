.c-select {
  border-radius: 1px;
  border: solid 1px var(--bbva-300);
  padding: 1rem;
  color: var(--bbva-500);
  font-size: 15px;
  -webkit-appearance: none;
  text-align: left;
  background-color: var(--bbva-100);
  width: 100%;

  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    background: url(../../../assets/icon/arrow-down-blue.svg) 94% center / 20px
      no-repeat var(--bbva-100);
  }
}

.c-select-options {
  .alert-radio-group {
    margin: 0px 0.9rem;

    .alert-radio-button {
      height: 4rem;
      border-bottom: 1px solid var(--bbva-300);
      cursor: pointer;

      .alert-radio-icon {
        display: none;
      }

      .alert-radio-label {
        padding-left: 0.625rem;
        text-overflow: clip;
        white-space: normal;
      }

      &[aria-checked='true'] {
        .alert-radio-label {
          color: var(--bbva-aqua);
          font-weight: $medium;
        }
      }
    }

    button:last-child {
      border-bottom: none;
    }
  }

  .alert-button-inner {
    color: var(--bbva-white-core-blue);
    height: 4rem;
  }
}

.c-textarea {
  --background: var(--bbva-100);
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-bottom: 0.82rem;
  --padding-top: 0.82rem;

  font-size: 0.75rem;
  height: 6.2rem;
  width: inherit;
  border-bottom: 0.065rem solid var(--bbva-500);

  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    height: 9.75rem;
  }
}
