@mixin aligns($align: null, $justify: null, $direction: null) {
  display: flex;

  @if $align {
    align-items: $align;
  }

  @if $justify {
    justify-content: $justify;
  }

  @if $direction {
    flex-direction: $direction;
  }
}
