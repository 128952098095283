@import 'src/theme/partials/variables';
@import 'src/theme/partials/mixins';

.c-card {
  margin: 0 auto;
  box-shadow: 0 0.13rem 0.25rem 0 var(--bbva-black-200);
  color: var(--bbva-600);
  padding: 1.3rem;

  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    padding: 2rem 1.5rem;
    min-height: 25rem;
  }

  &--min-height {
    @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
      min-height: auto;
    }
  }
}
