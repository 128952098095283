@font-face {
  font-family: 'BentonSansBBVA';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/BentonSansBBVA-Book.otf') format('opentype'),
    url('/assets/fonts/BentonSansBBVA-Book.svg#BentonSansBBVA-Book')
      format('svg'),
    url('/assets/fonts/BentonSansBBVA-Book.woff') format('woff'),
    url('/assets/fonts/BentonSansBBVA-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSansBBVA';
  font-style: normal;
  font-weight: $medium;
  src: url('/assets/fonts/BentonSansBBVA-Medium.otf') format('opentype'),
    url('/assets/fonts/BentonSansBBVA-Medium.svg#BentonSansBBVA-Medium')
      format('svg'),
    url('/assets/fonts/BentonSansBBVA-Medium.woff') format('woff'),
    url('/assets/fonts/BentonSansBBVA-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'BentonSansBBVA';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/BentonSansBBVA-Bold.otf') format('opentype'),
    url('/assets/fonts/BentonSansBBVA-Bold.svg#BentonSansBBVA-Bold')
      format('svg'),
    url('/assets/fonts/BentonSansBBVA-Bold.woff') format('woff'),
    url('/assets/fonts/BentonSansBBVA-Bold.woff2') format('woff2');
}
