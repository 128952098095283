ion-modal.retry-authentication-modal {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 45rem;
    --width: 43rem;
  }
}

ion-modal.register-time {
  --min-height: 34.375rem;
  --height: 41.5rem;
  --width: 43rem;
}

ion-modal.edit-time {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 45rem;
    --width: 43rem;
  }
}

ion-modal.reject-data {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 51rem;
    --width: 43rem;
  }
}

ion-modal.succes-validation-modal,
ion-modal.success-deletion-modal {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 47rem;
    --width: 43rem;
  }
}

ion-modal.confirm-succes-validation-modal {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 31rem;
    --width: 41rem;
  }
}

ion-modal.create-delegation {
  @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
    --height: 43rem;
    --width: 43rem;
  }
}

.c-authorization-modal {
  .info-modal {
    &__header {
      @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
        position: absolute;
        z-index: unset;
      }
    }
  }

  .top-buttons {
    @include aligns(null, flex-end);

    &__close {
      cursor: pointer;
      color: var(--bbva-medium-blue);
      padding-top: 1.12rem;
      padding-right: 1.12rem;
    }
  }

  .content {
    @include aligns(center, center, column);

    text-align: center;

    &__text {
      text-align: center;
      margin: auto;
      margin-top: 1.5rem;
      font-size: 0.875rem;

      @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
        text-align: left;
      }
    }

    &__remark {
      font-weight: $medium;
      font-size: 0.875rem;
      margin-top: 1.5rem;
    }

    &__icon {
      height: 3.62rem;
      width: 3.62rem;
    }

    &__title {
      margin-top: 1.15rem;
      font-size: 1.125rem;

      @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
        font-size: 1.75rem;
      }
    }
  }

  .actions {
    &__confirm,
    &__cancel {
      display: block;
      margin: auto;
      margin-top: 1.25rem;
    }
  }

  .wrapper {
    @include aligns(null, space-between, column);
    min-height: 100%;
  }

  .data-form {
    margin-top: 2rem;
    font-size: 0.93rem;
    width: 100%;

    &__comment {
      margin-top: 1rem;
      text-align: left;

      @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
        margin-top: 1.5rem;
      }
    }

    &__reasons--mobile {
      .select-icon {
        display: none !important;
      }
    }
  }

  .all {
    @include aligns(null, space-between, column);

    flex: 1;

    padding: 1.3rem 1.5rem 2rem;

    @media screen and (min-width: map-get($ion-grid-breakpoints, sm)) {
      padding: 0 8.3rem 3.4rem;
    }
  }
}

.sc-ion-modal-ios {
  display: contents;
}
